.challengeLayout {
  position: fixed;
  inset: 0;
  z-index: 9999;
  border-radius: 0;
  overflow: hidden;
  min-height: 100dvh;
  background-color: #FFF;

  @media(min-width: 1199px) {
    & {
      position: static;
    }
  }
}

.bgContainer{
  width: 100%;
  height: 100%;
  padding: 32px;
  position: relative;
  overflow: hidden;
  background-color: #000;

  .bgImg{
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.8;
      z-index: 1;
  }
}

.dressChallengeWrapper {
  width: 100%;
  display: block;
  grid-template-columns: 2fr 4fr;
  gap: 28px;
  place-items: center;
  padding: 0px;
  height: 78vh;

  @media (min-width: 1199px) {
    &{
      display: grid;
      height: calc(100dvh - 140px);
      padding: 20px;
    }
  }
}

.dressPreviewChild {
  width: 100%;
  height: 76vh;

  @media (min-width: 1199px) {
    &{
      height: calc(100% - 90px);
    }
  }
}

.dressChallengeWrapperDesktop {
  @media(max-width: 1199px) {
    display: none;
  }
}

.dressChallengeWrapperMbl {
  display: block;

  @media(min-width: 1199px) {
    display: none;
  }
}

.chatbotWrapper {
  height: 86vh;

  @media (min-width: 1199px) {
    &{
      height: calc(100dvh - 140px);
    }
  }
}

.chatbotScroller {
  max-height: 62vh;
  overflow-y: auto;
}

.challengePreviewWrapper {
  width: 100%;
  height: 100%;
}

.modelSection {
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: #E9F6F3;
  margin-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 16px;
}

.playButton {
  background-color: #20a58a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  height: 50px;
  width: 50%;
  margin: 12px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playButton:hover {
  background-color: #0056b3;
}

.editButton {
  // opacity: .6;
  // pointer-events: none;

  background-color: #20a58a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  height: 50px;
  width: 50%;
  margin: 12px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editButton:hover {
  background-color: #0056b3;
}

.buttonIcon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.editingBar {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 12px 26px;
  overflow: hidden;

  .bar {
    position: absolute;
    inset: 0;
    background: #FFFFFF33;
  }
}

.editingUndo {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 12px;
  border-radius: 10px;
  background: linear-gradient(120.66deg, rgba(194, 64, 15, 0.2) -4.27%, rgba(255, 116, 64, 0.2) 107.33%);

  img {
    width: 28px;
  }
}

.editingReset {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 12px;
  border-radius: 10px;
  background: linear-gradient(120.66deg, rgba(254, 181, 54, 0.2) -4.27%, rgba(205, 130, 0, 0.2) 107.33%);

  img {
    width: 28px;
  }
}

@media (max-width: 480px) {
  .editButton {
    width: 75%;
    margin: 5px 5px;
  }
  .playButton {
    width: 75%;
    margin: 5px 5px;
  }
}

.mblNavigation {
  display: block;
  padding: 16px 28px;
  border-radius: 20px;
  background-color: #FFFFFF;

  @media(min-width: 1199px) {
    & {
      display: none;
    }
  }

  .navigationBtnsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
  }

  .navigationBtn {
    padding: 10px 20px;
    border-radius: 20px;
    border: 1.6px solid transparent;
    transition: .1s ease-in;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: .2s ease-in;

    img{
      flex-shrink: 0;
    }

    span {
      font-size: 13px;
      color: #666666;
      display: block;
    }
  }

  .navigationBtnSelected {
    border-radius: 20px;
    border: 1.6px solid #C9379D;
    box-shadow: 0px 4px 8px -1px #ca56a2;

    span {
      display: block;
    }

  }
}