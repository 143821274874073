.modalOverlay {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999999999 !important;
}

.modalContent {
  min-width: 100% !important;
  background-color: #FFF;
  border-radius: 0px !important;
  max-height: 100dvh;
  overflow-x: hidden;
  margin-top: 0px !important;
  z-index: 9999999999 !important;
}

.skeletonContainer{
  background-color: #e2e5e7;
  border-radius: 8px;
  background-image:			
  linear-gradient(
    90deg, 
    rgba(#fff, 0), 
    rgba(#fff, 0.5),
    rgba(#fff, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: skeletonShine 1s ease infinite;
}

.skeletonContainer > *{
  opacity: 0;
}

@keyframes skeletonShine {
	to {
		background-position: right -40px top 0;
	}
}

.challengesSelectorWrapper {
  border-radius: 0px;
  background-color: #FFF;
  box-shadow: 0px -2px 12px 0px #4CBFA780 inset;
}

.headerWrapper {
  position: sticky;
  top: 0px;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: calc(100% - 24px);
  height: 94px;
  padding: 0px 12px;
  margin: 0 auto;
  background-color: #FFF;
}

.goBackBtn {
  cursor: pointer;
  border-radius: 12px;
  transition: .2 ease-in;

  &:hover {
    box-shadow: 0px -2px 12px 0px #c6469a69;
  }
}

.titleWrapper {
  text-align: center;

  h1 {
    color: #004F99;
    font-size: 24px;
    font-weight: 600;
    background: #0486FF;
    background: linear-gradient(to right, #0486FF 0%, #004F99 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span {
    font-size: 12px;
  }
}

.badge {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;
  border-radius: 50px;
  padding: 4px 10px;
  background-color: #3AAAFF26;

  img {
    width: 20px;
    object-fit: contain;
  }

  span {
    display: none;
    color: #3AAAFF;
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.descriptionWrapper {
  padding-top: 24px;
  color: #2C3E50;
  padding: 0px 24px;
  margin-bottom: 18px;

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  p {
    font-size: 20px;
  }
}

.challengesWrapper {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
  gap: 12px;
  padding: 0px 24px 24px 24px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  border-radius: 24px;
  box-shadow: 0px 0px 12px 0px #C3C3C3;

  &.cardSkeleton{
    $shine-width: 200px;
    $box-width: 400px;

    & > * {
      width: $box-width;
      height: $box-width;
      border-radius: 50%;
      overflow: hidden;
      
      &:empty {
        position: relative;
        background: #eee;
        
        &:before {
          content:'';
          transform: skewX(-45deg);
          background-image: linear-gradient(90deg, #eee 0px, rgba(255,255,255,.4) $shine-width/2, #eee $shine-width);
          position: absolute;
          background-repeat: repeat-x;
          left: -($box-width/2 + $shine-width); 
          width: $shine-width;
          height: 100%;
          animation: shine 1s infinite;
        }
      }
    }

    @keyframes shine {
      0% {
        left: -($box-width/2 + $shine-width);
      }
      100% {
        left: 3/2 * $box-width;
      }
    }
  }

  .cardHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    transition: .2s ease-in;
    margin-bottom: 12px;

    h3 {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      transition: .2s ease-in;
    }

    span {
      width: 24px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }

  .cardImgWrapper {
    aspect-ratio: 16/11;
    border-radius: 20px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: .2s ease-in;
      transform: scale(1);
    }
  }

  &:hover {
    box-shadow: 0px -2px 12px 0px #c6469a69;

    .cardHeader {
      h3 {
        background: #0486FF;
        background: linear-gradient(to right, #c84d9e 0%, #68184d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .cardImgWrapper {
      img {
        transform: scale(1.06);
      }
    }
  }

  .tooltip {
    position: relative;

    &::before {
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translatex(-50%) rotate(-45deg);
      content: "";
      display: none;
      width: 0;
      height: 0;
      border: 8px solid #FFF;
      border-top-color: transparent;
      border-right-color: transparent;
      box-shadow: 0px 0px 12px 0px #C3C3C3;
    }

    &::after {
      display: none;
      position: absolute;
      content: attr(data-tooltip);
      bottom: calc(100% + 8px);
      right: -10px;
      min-width: 298px;
      padding: 8px 12px;
      background-color: #FFF;
      border-radius: 8px;
      font-size: 12px;
      color: #344054;
      text-align: center;
      box-shadow: 0px 0px 12px 0px #C3C3C3;
    }

    &:hover:not(:focus)::before,
    &:hover:not(:focus)::after {
      display: block;
    }
  }
}


// media queries 

@media screen and (min-width: 768px) {
  .modalContent {
    min-width: 762px !important;
    border-radius: 24px !important;
  }

  .challengesSelectorWrapper {
    border-radius: 24px;
  }

  .challengesWrapper {
    flex-direction: row;
  }

  .headerWrapper {
    top: 6px;
  }

  .descriptionWrapper {
    padding-top: 0px;
  }

  .badge {
    img {
      width: 24px;
    }

    span {
      display: block;
    }
  }
}