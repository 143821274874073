@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Inter, serif;
}
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html {
  @media screen and (max-width: 1536px) {
    font-size: 80%;
  }
}

body {
  background-color: #d9e4e2;
}

.image-block a {
  display: block;
}
.image-block img {
  display: block;
  border-radius: 10px;
}
mux-player {
  --media-object-fit: cover;
  --media-object-position: center;
}
.__Latex__ .base {
  /* display: contents !important;
  word-wrap: break-word !important; */
}
.tree {
  margin-bottom: 10px;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 12px;
  position: relative;
}
.tree .person {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  position: relative;
}
.tree .person:last-child:not(:only-child):before {
  display: none;
}
.tree .person:only-child:before {
  bottom: auto;
  display: block;
  height: 26px;
}
.tree .person:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: -26px;
  content: '';
  background: #666;
  width: 1px;
}
.tree .person .line2 {
  width: 24px;
  height: 1px;
  background-color: #666666;
  margin-top: 26px;
}
.tree .person .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  grid-template: 'profile name' 'profile type';
  grid-gap: 3px;
}
.tree .person .grid .MuiAvatar-root {
  margin-top: 4px;
}
.tree .person .grid .profileImage {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  grid-area: profile;
  margin-right: 3px;
}
.tree .person .grid .name {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  font-weight: 600;
  font-size: 70%;
  color: #16191c;
  grid-area: name;
}
.tree .person .grid .type {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: type;
  font-weight: 300;
  font-size: 50%;
  color: #16191c;
}

.CircularProgressbar {
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.circularBarTextBelow {
  padding-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.CircularProgressbar p {
  font-size: 10px;
  width: 60px;
  white-space: unset !important;
}

.CircularProgressbarGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  padding-top: 5px;
  margin-bottom: 30px;
  width: 90%;
}

.SideBarFooterText {
  display: -ms-grid;
  display: grid;
  place-content: center;
  padding: 20px 0px;
  font-size: 14px;
  font-weight: 700;
}

mux-player::part(layer) {
  border-radius: 12px;
}

mux-player::part(bottom play button) {
  border-bottom-left-radius: 12px;
}

mux-player::part(bottom fullscreen button) {
  border-bottom-right-radius: 12px;
}

.fraction {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.fraction > span {
  display: block;
}

.fraction span.fraction--denominator {
  border-top: thin solid black;
}

.fraction span.fraction--bar {
  display: none;
}

.mathquill {
  font-size: 16px;
  display: inline-block;
}

.selected_reaction {
  background-color: #d9e4e2;
}

.btn-primary,
.btn-secondary,
.btn-outline {
  background-color: #4cbfa7;
  display: inline-block;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  min-width: 120px;
}
.btn-primary:hover,
.btn-secondary:hover,
.btn-outline:hover {
  background-color: #2b7a69;
}

.btn-secondary {
  background-color: #c73e9d;
}
.btn-secondary:hover {
  background-color: #7b2460;
}

.btn-outline {
  background-color: #d9e4e2;
  color: #16191c;
}
.btn-outline:hover {
  background-color: #9dbab4;
}

.img-cross {
  border-radius: 0 !important;
}

.form-area {
  padding: 0 120px;
}
.form-area .textField_textField__rjYw5 {
  margin-bottom: 10px;
}
.form-area .dark-text {
  display: block;
  font-weight: 700;
}

/* HTML: <div class="loader"></div> */
span.loader {
  width: 50px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #ffffff 90%, #ffffff00);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
span.loader.black {
  width: 50px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000000 90%, #00000000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.g3ms-chat {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}

.chat-toolbar {
  backdrop-filter: blur(32px);

  box-shadow: 0px 4px 12px 0px #ca56a2;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.phone-invalid--wrapper input,
.phone-invalid--wrapper button {
  border-color: #ff4d49 !important;
}

.phone-invalid--wrapper button {
  border-right-color: transparent !important;
}

.phone-invalid--wrapper .phone-invalid-message {
  display: block;
  color: #ff4d49;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25;
  letter-spacing: 0.4px;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.otp_validation--container > div > div {
  gap: 12px !important;
}

.otp_validation--container input[type='tel'] {
  width: 100% !important;
  margin-right: 0 !important;
}

.react-flag--select > ul {
  min-width: 180px;
}

@keyframes otpShake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

.otp-shake {
  animation: otpShake 0.5s ease-in-out;
}

/*  */
.convinceToUpgradeBody {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.convinceToUpgradeBodyInner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: center;
  text-align: center;
  transition: all 0.3s ease; /* Smooth transition on hover */
  opacity: 0.7;
}

.slick-center .convinceToUpgradeBodyInner {
  opacity: 1; /* Highlight the active item */
  grid-template-columns: 2fr;
}

.convinceToUpgradeBodyInner img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.convinceToUpgradeBodyInner:hover {
  grid-template-columns: 2fr;
  opacity: 1;
}

.heading {
  font-size: 18px;
  margin-top: 10px;
  color: #333;
}

.slick-prev,
.slick-next {
  z-index: 1;
  background-color: #35a28b;
  border-radius: 50%;
}

.slick-prev:before,
.slick-next:before {
  color: white;
}

.child-flex {
  display: flex;

  gap: 12px;

  align-items: baseline;

  justify-content: space-between;
}

.child-flex > div {
  width: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.recommended-price {
  border: 4px solid;
  border-image-source: linear-gradient(90deg, #347769 0%, #20a58a 100%);
}

.carousel-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto; /* Center align if needed */
}

.slick-slider {
  width: 100% !important;
}

/* hide up arrow and down arrow in input type number */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spinner {
  width: var(--spinner-size, 48px);
  height: var(--spinner-size, 48px);
  border: var(--spinner-border, 3px) solid var(--spinner-color, #fff);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100vw !important;
  height: 100% !important;
}

.inner-shadow {
  box-shadow: 0px 0px 16px 0px #4cbfa780 inset !important;
  box-shadow: 0px 4px 16px 0px #4cbfa7 inset !important;
}
