.modalOverlay {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999999999 !important;
}

.modalContent {
  border-radius: 0px;
  background-color: #FFF;
  max-height: 100dvh;
  overflow-x: hidden;
  margin-top: 0px !important;
  z-index: 9999999999 !important;

  @media screen and (min-width: 768px) {
    &{
      border-radius: 16px;
    }
  }
}

.modalOuterShadow {
  box-shadow: none;
  border-radius: 1rem;

  @media screen and (min-width: 768px) {
    &{
      box-shadow: 0px 0px 16px 0px #4CBFA780 inset;
    }
  }
}

.convinceToUpgradeContainer {
  height: 100dvh;
  padding: 24px 0px;
  box-shadow: none;
  background-color: #FFF;

  @media screen and (min-width: 768px) {
    &{
      height: auto;
      padding: 32px 0px;
      box-shadow: 0px 4px 16px 0px #4CBFA7 inset;
    }
  }
}

.convinceToUpgradeHead {
  position: relative;
  display: flex;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;

  .close{
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    &{
      padding: 0px 32px;
    }
  }
}

.heading{
  font-size: 20px;
  color: #2C3E50;
  font-weight: 700;
  margin-bottom: 12px;
}

.convinceToUpgradeBody {
  padding: 0px 24px;
  max-height: unset;
  overflow-y: auto;

  .bodyTxt{
    font-size: 16px;
    color: #000000;
    margin-bottom: 12px;
  }

  .bodySmTxt{
    font-size: 15px;
    margin-bottom: 8px
  }

  ul{
    font-size: 15px;
    margin-bottom: 14px;

    li {
      margin-bottom: 4px;
    }
  }

  @media screen and (min-width: 768px) {
    &{
      max-height: 58vh;
      padding: 0px 32px;
    }
  }
}

.convinceToUpgradeFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  padding: 0px 24px;
  margin-top: 16px;
  padding-bottom: 26px;

  button {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 0px;
    border-radius: 12px;
    padding: 14px 20px;
    transition: .2s ease-in;
    background-color: #C44297;

    &:hover {
      background-color: #a83a82;
    }

    &:nth-child(1) {
      background: #35A28B;
      
      &:hover {
        background-color: #31937e;
      }
    }

    &.disabledButton {
      color: #FFFFFF;
      background-color: #31937e90;
      pointer-events: none;
    }

    @media screen and (min-width: 768px) {
      &{
        gap: 16px;
        font-size: 16px;
        margin-top: 20px;
        padding: 16px 20px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &{
      flex-direction: row;
      padding: 0px 32px;
      padding-bottom: 0;
    }
  }
}