@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Press+Start+2P&display=swap');


.todaysChallengeMain {
    position: relative;
    align-items: center;

    * {
        white-space: nowrap;
    }

    display: flex;
    height: 100%;
    grid-gap: 10px;
    width: 100%;
    grid-template: "image text";
    grid-template-rows: auto;
    grid-template-columns: 1fr 2fr;
    border: 6px solid #33C97F;
    border-radius: 20px;
    padding: 10px 0px;
}


.todaysChallengeMainFullpage {
    position: relative;

    * {
        white-space: nowrap;
    }

    width: 720px;
    display: grid;
    place-content: center;
    height: 400px;
    grid-gap: 10px;
    grid-template: "image"
    "text";
    grid-template-rows: auto;
    // grid-template-columns: 1fr;
    border: 11px solid #33C97F;
    border-radius: 20px;
    padding: 60px 33px;
    background-image: url("../../../../../media/TodaysChallengeImageBackground.jpg");
    ;
}

.todaysChallengeMainFull {
    position: sticky;

    * {
        white-space: nowrap;
    }

    width:"100%";
    max-width: 560px;
    display: grid;
    place-content: center;
    height: 400px;
    grid-gap: 10px;
    grid-template: "image"
    "text";
    grid-template-rows: auto;
    // grid-template-columns: 1fr;
    border: 8px solid #33C97F;
    border-radius: 20px;
    padding: 20px 33px;
}

.todaysChallengeMainImage {
    grid-area: image;
    margin-right: 20px;
}

.todaysChallengeMainImageFull {
    grid-area: image;
}

.todaysChallengeImage {
    width: 100%;
    border-radius: 20px;
}

.todaysChallengeImageFull {
    height: 148.8px;
    width: 153.16px;
    border-radius: 12px;
}

.todaysChallengeBodyFullpage {
    background-color: #fff;
    width: 400px;
    height: 220px;
    padding: 25px 30px;
    border-radius: 39px;
    margin-top: 60px;
    margin-right: 70px;

    p {
        grid-area: text;
        font-size: 24px;
    }

    h6 {
        grid-area: text;
        font-size: 32px;
    }
}

.todaysChallengeBody p {
    grid-area: text;
    font-size: 14px;
}

.todaysChallengeBodyFull p {
    grid-area: text;
    font-size: 24px;
}

.todaysChallengeBody h6 {
    grid-area: text;
    font-size: 16px;
}

.todaysChallengeBodyFull h6 {
    grid-area: text;
    font-size: 32px;
}

.todaysChallengeAvatars {
    display: flex;
    list-style-type: none;
    margin: auto;
    padding: 5px 10px;
    flex-direction: row;

    .avatars__item {
        border-radius: 100%;
        color: #ffffff;
        display: block;
        font-size: 12px;
        font-weight: 100;
        height: 25px;
        width: 25px;
        text-align: center;
        transition: margin 0.1s ease-in-out;
        overflow: hidden;
        margin-left: -10px;

        &:first-child {
            z-index: 1;
        }

        &:nth-child(2) {
            z-index: 2;
        }

        &:nth-child(3) {
            z-index: 3;
        }

        &:nth-child(4) {
            z-index: 4;
        }

        &:nth-child(5) {
            z-index: 5;
        }

        &:last-child {
            z-index: 0;
            height: 25px;
            width: 25px;
        }

        img {
            width: 100%
        }
    }
}

.todaysChallengeButton {
    padding: 5px 10px;
    color: #fff;
    background-color: #20A58A;
    font-size: 10px;
    border-radius: 25px;
    border: none;
    text-transform: uppercase;
}

.buttonstodaysChallenge {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rigthSideDoubleArrow {
        position: absolute;
        right: 30.11px;
        bottom: 12px;
    }
}

.overlay-layers {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .layer1 {
        position: absolute;
        right: -17px;
        z-index: -1;
        border-radius: 18px;
        border-right: 12px solid #79C9B9;
        width: 631px;
        height: 100%;
    }

    .layer2 {
        position: absolute;
        border-right: 12px solid #DD8BC4;
        top: 9px;
        right: -29px;
        border-radius: 17px;
        width: 647px;
        height: 86%;
        z-index: -3;
    }
}

.overlay-layersFull {
    position: absolute;
    width: 100%;
    height: 100%;

    .layer1Full {
        position: absolute;
        right: -20px;
        z-index: -1;
        border-radius: 16px;
        border-right: 14px solid #79C9B9;
        width: 631px;
        height: 378px;
    }

    .layer2Full {
        position: absolute;
        border-right: 14px solid #DD8BC4;
        top: 7px;
        right: -34px;
        border-radius: 16px;
        width: 647px;
        height: 362px;
    }
}

.overlay-layersFullpage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    .layer1Fullpage {
        position: absolute;
        right: -26px;
        z-index: -1;
        border-radius: 16px;
        border-right: 17px solid #79C9B9;
        width: 631px;
        height: 376px;
    }

    .layer2Fullpage {
        position: absolute;
        border-right: 17px solid #DD8BC4;
        top: 7px;
        right: -41px;
        border-radius: 16px;
        width: 647px;
        height: 360px;
    }
}