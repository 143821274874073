@media (min-width: 300px) {
  .selectAssignmentList {
    grid-area: subList;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    text-align: center;
    gap: 10px;
    align-items: center;
    background-color: transparent;
    padding: 14px 10px 0 0;
    overflow: auto;

    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }

    .item {
      all: unset;
      cursor: pointer;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 10px;
      max-width: 100%;
      max-width: 252px;
      border-radius: 12px;
      padding: 8px 18px;
      text-align: center;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 2px solid #c73e9d;
      transition: .3s;
      color: #c73e9d;
    }

    .item:hover {
      background-color: #c73e9d;
      color: #fff;
    }

    .selected {
      background-color: #c73e9d;
      color: #f9f9f9;
    }
  }

  #selectAssignmentList::-webkit-scrollbar {
    height: 5px;
    border-radius: 5px;
  }

  #selectAssignmentList::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  #selectAssignmentList::-webkit-scrollbar-thumb {
    background: #c73e9d;
    border-radius: 5px;
  }
}

@media (min-width: 1600px) {
  .selectAssignmentList {
    grid-area: subList;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;

    text-align: center;
    gap: 10px;
    align-items: center;
    background-color: transparent;
    padding: 16px 18px 0 0;
    overflow: auto;

    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }

    .item {
      all: unset;
      cursor: pointer;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 14px;
      max-width: 100%;
      border-radius: 12px;
      padding: 8px 18px;
      text-align: center;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 2px solid #c73e9d;
      transition: .3s;
      color: #c73e9d;
    }

    .item:hover {
      background-color: #c73e9d;
      color: #fff;
    }

    .selected {
      background-color: #c73e9d;
      color: #f9f9f9;
    }
  }

  #selectAssignmentList::-webkit-scrollbar {
    height: 5px;
    border-radius: 5px;
  }

  #selectAssignmentList::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  #selectAssignmentList::-webkit-scrollbar-thumb {
    background: #c73e9d;
    border-radius: 5px;
  }
}

.appStore {
  display: flex;
}

.googlePlay {
  display: flex;
}
