.chatSection {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
  border: 2px solid transparent;
  border-radius: 10px;
  margin-right: 20px;
  overflow: hidden;
  height: 78vh;

  @media (min-width: 1200px){
    height: calc(100dvh - 140px);
    border: 2px solid #20A58A;
  }
}


.skeletonContainer{
  background-color: #e2e5e7;
  border-radius: 8px;
  background-image:			
  linear-gradient(
    90deg, 
    rgba(#fff, 0), 
    rgba(#fff, 0.5),
    rgba(#fff, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: skeletonShine 1s ease infinite;

  &.skeletonCircle{
    border-radius: 100px;
  }

  &.skeletonBtn{
    width: 100%;
  }
}

.skeletonContainer > *{
  opacity: 0;
}

@keyframes skeletonShine {
	to {
		background-position: right -40px top 0;
	}
}

.disableForm{
  opacity: .5;
  pointer-events: none;
}

.chatOptionsWrapper{
  box-shadow: 0px -2px 12px 0px #4CBFA780 inset;
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 14px;
}

.chatOptionsTitle{
  font-size: 18px;
  font-weight: 500;
  color: #2C3E50;
  margin-bottom: 14px;
}

.colorsWrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 14px;
  border-radius: 24px;

  .materialCard{
    width: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 12px 0px #C3C3C3;

    .colorBg{
      position: relative;
      width: 100%;
      aspect-ratio: 1/.8;
      display: flex;
      margin: 0 auto;
      border-radius: 8px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      justify-content: center;

      object-fit: contain;
      object-position: center;

      span{
        width: 10px;
        height: 10px;
        background-color: #FFF;
        border: 2px solid #FFFFFF4D;
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 100px;
      }
    }

    h3{
      padding-top: 12px;
      font-size: 13px;
      font-weight: 700;
      padding: 14px;
    }
  }
}

.materialsWrapper{
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  gap: 14px;
  border-radius: 24px;

  .materialCard{
    width: 100%;
    height: 100%;
    padding: 14px;
    border-radius: 24px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 12px 0px #C3C3C3;

    img{
      width: 100%;
      aspect-ratio: 1/.6;
      display: flex;
      margin: 0 auto;
      border-radius: 10px;
      justify-content: center;
      margin-bottom: 12px;

      object-fit: cover;
      object-position: center;
    }

    h3{
      font-size: 14px;
      font-weight: 700;
      text-align: center;
    }
  }
}

.movementWrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 14px;
  border-radius: 24px;

  .materialCard{
    width: 100%;
    height: 100%;
    padding: 14px;
    border-radius: 24px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 12px 0px #C3C3C3;

    img{
      width: 100%;
      aspect-ratio: 1/1;
      display: flex;
      margin: 0 auto;
      border-radius: 10px;
      justify-content: center;
      margin-bottom: 12px;

      object-fit: cover;
      object-position: center;
    }

    h3{
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.chatSectionMobile {
  width: 70%;
  margin-top: 20px;
  overflow-x: hidden;
}

.chatbotScroller{
}

.chatBox {
  margin-bottom: 20px;
  border-radius: 10px;
}

.messageCard {
  padding: 20px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px -2px 16px 0px #20A58A40 inset;
}
.messageCard1 {
  padding: 20px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px -2px 16px 0px #20A58A40 inset;
}

.senderInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.senderName {
  font-weight: 500;
  font-size: 12px;
  color: #475467;
}

.senderTime {
  font-size: 12px;
  color: #475467;
}

.userMessage {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.userPic {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 8px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.userMessageContent {
  font-size: 10px;
  border-radius: 10px;
  max-width: 80%;
}
.userName {
  font-size: 13px;
  color: #475467;
  font-weight: bold;
  padding-left: 3px;
}

.userMessageText {
  font-size: 13px;
  color: #475467;
  font-weight: bold;
  padding-left: 3px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  gap: 8px;
}

.optionButton {
  width: 50%;
  height: 50px;
  border-radius: 10px;
  background-color: #20A58A;
  color: white;
  margin-bottom: 0;
  box-shadow: 0px 4px 12px 0px #FFFFFF66 inset;
}

.optionButtonWFull{
  width: 100%;
}

.optionButtonYes {
  background-color: #20A58A;
}

.optionButtonNo {
  background-color: #FF6B6B;
}

.isTyping {
  font-style: italic;
  font-size: 12px;
  color: #999;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.typingUserPic {
  width: 35px;
  height: 35px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.typingText {
  padding: 5px;
  font-style: italic;
  font-size: 12px;
  color: #999;
  display: flex;
  flex-direction: column;
}

.typingBubble {
  width: 35px;
  height: 20px;
  box-shadow: none;
}

.form {
  width: 100%;
  height: 160px;
  background: #ffffff;
}

.inputArea {
  position: absolute;
  bottom: 0;
  width: calc(100% - 16px);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px 8px;
  margin: 8px;
  border: 1px solid #00000033;
  height: 48px;
  border-radius: 50px;
  background-color: #A8DADC33;
  backdrop-filter: blur(32px);
  box-shadow: 0px 4px 12px 0px #CA56A2;
  margin-bottom: 12px;
}

.input {
  width: 100%;
  padding: 8px 20px;
  font-size: 11px;
  color: #000;
  height: 36px;
  font-weight: bold;
  border-radius: 25px;
  background-color: #FFFFFF;
  border: 1px solid #00000033;
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 30px;
  padding: 0;
  background: #FFFFFF;
  border: 2px solid #00000033;

  img{
    width: 16px;
  }
}
