.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.gemsText {
  display: flex;
  text-align: center;
  color: #c73e9d;
  font-weight: 700;
  font-size: 20px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  place-items: center;
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
}

.card {
  background-color: #f8f8f8;
  border: 2px solid #20a58a;
  margin-top: 20px;
  border-radius: 12px;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  max-width: 567px;
  width: 100%;
  box-shadow: 0px 4px 10px 0px #00000026;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-right: 20px;
  p {
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }
}

@media (min-width: 920px) {
  .header {
    p {
      font-size: 18px;
    }
  }
}

@media (min-width: 1720px) {
  .header {
    p {
      font-size: 32px;
    }
  }
}

.title {
  font-size: 18px;
  color: #000;
  font-weight: 900;
  @media (min-width: 920px) {
    font-size: 24px;
  }
  @media (min-width: 1220px) {
    font-size: 32px;
  }
  @media (min-width: 1920px) {
    font-size: 44px;
  }
}

.leaderboard {
  height: 100%;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: white;
}

.searchWrapper {
  height: 45px;
  max-width: 500px;
  width: 100%;
  border-radius: 24px;
  padding: 0px 20px;
  position: relative;
  background-color: #d9e4e2;
  input {
    height: 45px;
    max-width: 500px;
    width: 100%;
    border-radius: 24px;
    background-color: #d9e4e2;
    border: none;
    padding: 0px 35px;
    color: #999999;
    font-weight: 600;
    font-size: 20px;
  }
  input:focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #999999;
  }

  img {
    position: absolute;
    top: 10px;
    width: 26px;
    height: 23px;
  }
}

.inputContainer {
  margin-top: 20px;
  align-items: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-around;
  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 0px;
  }
}

.back_btn {
  cursor: pointer;
  width: 32px;
  &:hover {
    background-color: #d9e4e2;
    border-radius: 100px;
    cursor: pointer;
  }
}
