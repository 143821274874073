.headerWrapper {
    position: sticky;
    top: 0px;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    height: 94px;
    padding: 16px 20px;
    margin: 0 auto;
    background-color: #FFF;

    @media(min-width: 1200px){
        &{
            top: 6px;
        }
    }
}

.goBackBtn {
    cursor: pointer;
    border-radius: 12px;
    transition: .2 ease-in;

    &:hover {
        box-shadow: 0px -2px 12px 0px #c6469a69;
    }
}

.badge {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 6px;
    border-radius: 50px;
    padding: 4px 10px;
    background-color: #3AAAFF26;

    img {
        width: 20px;
        object-fit: contain;
    }

    span {
        display: none;
        color: #3AAAFF;
        font-size: 24px;
        font-weight: 500;
        white-space: nowrap;

        @media (min-width: 768px) {
            &{
                display: block
            }
        }
    }

}

.titleWrapper {
    text-align: center;

    h1 {
        color: #004F99;
        font-size: 24px;
        font-weight: 600;
        background: #0486FF;
        background: linear-gradient(to right, #0486FF 0%, #004F99 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    span {
        font-size: 12px;
    }
}