.modalOverlay {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999999999 !important;
}

.modalContent {
  width: calc(100vw - 36px) !important;
  border-radius: 0px;
  background-color: #FFF;
  height: calc(100dvh - 36px);
  overflow-x: hidden;
  margin-top: 0px !important;
  z-index: 9999999999 !important;

  @media screen and (min-width: 768px) {
    &{
      border-radius: 16px;
    }
  }
}

.modalOuterShadow {
  height: 100%;
  box-shadow: none;

  @media screen and (min-width: 768px) {
    &{
      box-shadow: 0px 0px 16px 0px #4CBFA780 inset;
    }
  }
}

.convinceToUpgradeContainer {
  min-height: 100%;
  padding: 24px 0px;
  box-shadow: none;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    &{
      padding: 32px 0px;
      box-shadow: 0px 4px 16px 0px #4CBFA7 inset;
    }
  }
}

.convinceToUpgradeHead {
  position: relative;
  display: flex;
  padding: 0px 24px;
  justify-content: center;
  margin-bottom: 20px;

  .close{
    position: absolute;
    right: 30px;
    top: -8px;
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    &{
      padding: 0px 32px;
    }
  }
}

.convinceToUpgradeBody {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 34px;

  .convinceToUpgradeBodyInner {
    width: 100%;
    border-radius: 28px;
    overflow: hidden;
    border: 2px solid #E9EAEB;
    box-shadow: 0px 2.52px 5.04px 0px;
    box-shadow: 0px 2.52px 5.04px 0px #E9EAEB;
  }

  img {
    width: 100%;
    height: 380px;
    object-fit: scale-down;
    object-position: top;
  }

  .heading{
    font-size: 28px;
    color: #181D27;
    font-weight: 700;
    text-align: center;
    margin-top: 32px;
    padding: 0 32px;
    margin-bottom: 12px;
  }

  .bodyTxt{
    font-size: 16px;
    color: #000000;
    text-align: center;
    margin-bottom: 28px;
  }

  .bodySmTxt{
    font-size: 15px;
    text-align: center;
    margin-bottom: 8px
  }

  ul{
    font-size: 15px;
    margin-bottom: 14px;
  }

  @media screen and (min-width: 768px) {
    &{
      padding: 0px 32px;
    }
  }
}

.convinceToUpgradeFooter {
  padding: 0px 24px;
  
  button {
    width: 280px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 16px;
    border-radius: 12px;
    padding: 14px 20px;
    background-color: #C44297;

    @media screen and (min-width: 768px) {
      &{
        font-size: 16px;
        margin-top: 20px;
        padding: 16px 20px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &{
      padding: 0px 32px;
    }
  }
}

.codeField {
  margin-bottom: 20px;

  label {
    display: block;
    font-size: 14px;
    color: #484848;
    margin-bottom: 6px;
  }

  input {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #00000033;
  }
}