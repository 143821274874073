.videoPlayerMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.videoPlayerBody {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.videoPlayer {
  padding: 3% 30px;
  border-radius: 25px;
}

.videoResponseButtons {
  height: 200px;
  width: 60px;
  border-radius: 48px;
  background-color: #d9e4e2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 10px;
}

.videoResponseButton {
  padding: 10px;
  position: relative;
}

.videoResponseButtons button {
  border-radius: 100%;
  // width: 100%;
  aspect-ratio: 1/1;
  background-color: #20a58a;
  border: none;
  display: grid;
  place-content: center;
  margin: 6px 0;
}

.videoResponseButtons button.videoResponseButton {
  margin: 0px;
}

.reactButtonVideoPlayer {
  border-radius: 100%;
  aspect-ratio: 1/1;
  background-color: #20a58a;
  border: none;
  display: grid;
  place-content: center;
  margin: 15px 0;
}

.VideoPlayerAvatars {
  display: flex;
  list-style-type: none;
  margin: auto;
  flex-direction: row;

  .avatars__item {
    border-radius: 100%;
    color: #ffffff;
    display: block;
    text-align: center;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      padding: 2px;
      border-radius: 50%;
    }
  }
}

.status {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  font-size: 18px;
  right: -5px;
  color: #ffffff;
  font-weight: 900;
}

.active {
  background-color: #c73e9d;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: flex;
  // background-color: #20A58A;
  border-radius: 100%;
  aspect-ratio: 1/1;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  padding: 8px;
  height: 70px;
}

.dropdown-content {
  height: 70px;
  display: none;
  position: absolute;
  background-color: #332b2b70;
  padding: 0 18px 0 0x;
  right: 70px;
  border-radius: 30px 0 0 30px;
  width: 190px;
  padding: 0 0 0 20px;
  justify-content: center;
  text-align: center;
}

.dropdown-content button {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  background-color: none;
  width: 30px;
  height: 30px;

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover {
  border-radius: 0 100% 100% 0;
  background-color: rgba(51, 43, 43, 0.44);
}

.videoName {
  display: flex;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.dropdown-heading {
  width: 100%;
  text-align: center;

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
  }
}

.img-user-outline {
  border: 8px solid #20a58a;
}