@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Press+Start+2P&display=swap');

.gridContainer {
  padding: 20px;

  * {
    white-space: nowrap;
  }

  // max-width: 100vw;
  height: 100dvh;
  display: grid;
  grid-gap: 15px;
  grid-template:
    'side nav nav'
    'side subList subList'
    'side mainBody mainBody'
    'side mainBody mainBody';
  grid-template-rows: 4rem 3rem auto;
  grid-template-columns: 15.5rem auto;
}

.gridContainerAll {
  all: unset;
  display: flex;
  justify-content: space-between;
  grid-column: 1/4;
  width: 100%;
  height: 71vh;
  overflow: auto;
  // padding-bottom: 20px;
}

.gridContainerChallengeTask {
  * {
    white-space: nowrap;
  }

  display: grid;
  grid-gap: 160px;
  grid-template: 'stats main';
  // grid-template-rows: 118px 90px auto;
  // grid-template-columns: 50% 50%;
}

.gridContainerVideoTask {
  * {
    white-space: nowrap;
  }

  display: grid;
  grid-gap: 30px;
  grid-template: 'stats main';
  // grid-template-rows: 118px 90px auto;
  grid-template-columns: 1fr 1fr;
}

.gridContainerVideoLayout {
  * {
    white-space: nowrap;
  }

  grid-gap: 30px;
  display: block;
  width: 100%;
}

@media screen and (min-width: 1600px) {
  .gridContainer {
    grid-template:
      'side nav nav'
      'side subList subList'
      'side mainBody mainBody'
      'side mainBody mainBody';
    grid-template-rows: 6rem 4rem auto;
    grid-template-columns: 18rem auto;
  }
}

@media screen and (max-width: 1280px) {
  .gridContainer {
    grid-template:
      'side nav nav'
      'side subList subList'
      'side stats main'
      'side videos main';
    grid-template-rows: 4rem 3rem auto;
    grid-template-columns: 15.5rem auto;
  }
}

@media screen and (min-width: 1600px) {
  .gridContainerAll {
    height: 71vh;
  }
}

@media screen and (max-width: 1600px) {
  .gridContainerAll {
    height: 79vh;
  }
}

@media screen and (max-width: 1400px) {
  .gridContainer {
    grid-template:
      'side nav nav'
      'side subList subList'
      'side mainBody mainBody'
      'side mainBody mainBody';
    grid-template-rows: 4rem 3rem auto;
    grid-template-columns: 16rem auto;
  }

  .gridContainerAll {
    height: 71vh;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .gridContainerAll {
    flex-wrap: wrap;
    height: 79vh;
  }
}

.whiteContainer {
  background-color: white;
  border-radius: 35px;
  width: 100%;
}

#show-all-content {
  margin-left: 35px;
}

.whiteContainerChallengeTask {
  background-color: white;
  border-radius: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteContainerVideo {
  background-color: white;
  border-radius: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: +1;
}

.whiteContainerMath {
  background-color: white;
  border-radius: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainBodies {
  grid-area: mainBody;
}

.button {
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #d9e4e2;
  font-size: 1em;
  cursor: pointer;
  border-radius: 24.5px;

  // padding that is max 14px 22px; and min  7px 5px;
  padding: min(14px, 40%) min(22px, 10%);

  gap: 6px;
  width: fit-content;
  // text not break
  white-space: nowrap;

  &:hover {
    filter: brightness(0.9);
  }
}

.arrow-image {
  transition: transform 0.35s;
}

.arrow-expanded {
  transform: rotate(180deg);
}
