
.messageCard {
  padding: 20px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px -2px 16px 0px #20A58A40 inset;
}

.optionButton {
  width: 50%;
  height: 50px;
  border-radius: 10px;
  background-color: #20A58A;
  color: white;
  margin-bottom: 0;
  box-shadow: 0px 4px 12px 0px #FFFFFF66 inset;
}

.optionButtonWFull{
  margin-top: 12px;
  width: 100%;
}

.optionButtonYes {
  background-color: #20A58A;
}

.optionButtonNo {
  background-color: #FF6B6B;
}