.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 10;
  // background blur
}

.content {
  z-index: 12;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 2px;
  border-radius: 3px;
  background-color: #f5f5f5;
  width: min(100%, 750px) !important;
}
