
/* Matte Animation (Sharp, Step-by-Step Movement) */
@keyframes matteAnimation {
  25% { transform: translateX(10px); }
  50% { transform: translateX(20px); }
  0% { transform: translateX(0); }
  75% { transform: translateX(-30px); }
  100% { transform: translateX(-40px); }
}

.movement--matte {
  animation: matteAnimation 2s steps(5, end) infinite;
  animation-fill-mode: forwards;
}

/* Mesh Animation (Short, Quick Steps) */
@keyframes meshAnimation {
  20% { transform: translateX(5px); }
  40% { transform: translateX(10px); }
  0% { transform: translateX(0); }
  60% { transform: translateX(-15px); }
  80% { transform: translateX(-20px); }
  100% { transform: translateX(-25px); }
}

.movement--mesh {
  animation: meshAnimation 1.5s ease-in-out infinite alternate;
  animation-fill-mode: forwards;
}

/* Smooth Animation (Gliding Left to Right) */
@keyframes smoothAnimation {
  0% { transform: translateX(-50px); }
  50% { transform: translateX(0); }
  100% { transform: translateX(50px); }
}

.movement--smooth {
  animation: smoothAnimation 2s ease-in-out infinite alternate;
  animation-fill-mode: forwards;
}

/* Glossy Animation (Circular, Spinning Movements) */
@keyframes glossyAnimation {
  0% { transform: translateX(-20px); }
  40% { transform: translateX(0); }
  50% { transform: translateY(20px); }
  65% { transform: translateY(0px); }
  100% { transform: translateX(20px); }
}

.movement--glossy {
  animation: glossyAnimation 3s linear infinite;
  animation-fill-mode: forwards;
}