
.chatOptionsWrapper{
    box-shadow: 0px -2px 12px 0px #4CBFA780 inset;
    padding: 20px;
    border-radius: 24px;
    margin-bottom: 14px;
  }

  .chatOptionsTitle{
    font-size: 18px;
    font-weight: 500;
    color: #2C3E50;
    margin-bottom: 14px;
  }

  .colorsWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 14px;
    border-radius: 24px;

    .materialCard{
      width: 100%;
      border-radius: 8px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 12px 0px #C3C3C3;

      .colorBg{
        position: relative;
        width: 100%;
        aspect-ratio: 1/.8;
        display: flex;
        margin: 0 auto;
        border-radius: 8px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        justify-content: center;

        object-fit: contain;
        object-position: center;

        span{
          width: 10px;
          height: 10px;
          background-color: #FFF;
          border: 2px solid #FFFFFF4D;
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 100px;
        }
      }

      h3{
        padding-top: 12px;
        font-size: 13px;
        font-weight: 700;
        padding: 14px;
      }
    }
  }

  .materialsWrapper{
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 14px;
    border-radius: 24px;
    margin-top: 12px;

    .materialCard{
      width: 100%;
      height: 100%;
      padding: 14px;
      border-radius: 24px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 12px 0px #C3C3C3;
      cursor: pointer;

      img{
        width: 100%;
        aspect-ratio: 1/.6;
        display: flex;
        margin: 0 auto;
        border-radius: 10px;
        justify-content: center;
        margin-bottom: 12px;

        object-fit: cover;
        object-position: center;
      }

      h3{
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  .movementWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 14px;
    border-radius: 24px;

    .materialCard{
      width: 100%;
      height: 100%;
      padding: 14px;
      border-radius: 24px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 12px 0px #C3C3C3;

      img{
        width: 100%;
        aspect-ratio: 1/1;
        display: flex;
        margin: 0 auto;
        border-radius: 10px;
        justify-content: center;
        margin-bottom: 12px;

        object-fit: cover;
        object-position: center;
      }

      h3{
        font-size: 14px;
        font-weight: 700;
      }
    }
  }