.selectAvatar {
  width: min(100%, 750px) !important;

  .title {
    color: #131515;
    font-weight: 600;
    font-size: 24px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px;

    .avatar {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
    }

    img {
      max-width: 100px;
      transition: all 0.1s ease-in-out;
    }

    .selected {
      box-shadow: 0 0 0 4px #ffc000;
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 750px) {
    .grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
