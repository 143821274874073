.playContainer{
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);

    @media (min-width: 1200px){
      &{
        height: calc(100dvh - 140px);
        border-radius: 10px;
      }
    }
}

.editingBar {
    position: absolute;
    z-index: 9999;
    top: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    padding: 12px 26px;
  
    .bar {
        position: absolute;
        inset: 0;
        border-radius: 8px;
        background: rgb(119 149 155);
        height: 58px;
        top: 0px;
    }
  }
  
  .editingUndo {
    position: absolute;
    z-index: 9999;
    top: 5px;
    left: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 12px;
    border-radius: 10px;
    cursor: pointer;
    width: 96px;
    transition: .2s ease-in;
    box-shadow: 0px 4px 12px 0px #FFFFFF66 inset;
    background: linear-gradient(120.66deg, rgba(194, 64, 15, 0.2) -4.27%, rgba(255, 116, 64, 0.2) 107.33%);

    &:hover{
        background: linear-gradient(120.66deg, rgb(194, 64, 15, 43%) -4.27%, rgb(255, 116, 64, 43%) 107.33%);
    }
  
    img {
      width: 28px;
    }
  }
  
  .editingReset {
    position: absolute;
    z-index: 9999;
    top: 5px;
    right: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 12px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 12px 0px #FFFFFF66 inset;
    background: linear-gradient(120.66deg, rgba(254, 181, 54, 0.2) -4.27%, rgba(205, 130, 0, 0.2) 107.33%);

    &:hover{
        background: linear-gradient(120.66deg, rgb(254, 181, 54, 43%) -4.27%, rgb(205, 130, 0, 43%) 107.33%);
    }

    img {
      width: 28px;
    }
  }
  
  @media (max-width: 480px) {
    .editButton {
      width: 75%;
      margin: 5px 5px;
    }
    .playButton {
      width: 75%;
      margin: 5px 5px;
    }
  }

.goBackBtn {
    position: absolute;
    z-index: 3;
    top: 20px;
    left: 20px;
    cursor: pointer;
    border-radius: 12px;
    transition: .2 ease-in;

    &:hover {
        box-shadow: 0px -2px 12px 0px #c6469a69;
    }
}

.triggerBtn {
    border-radius: 100px;
    background-color: #FF6B6B;
    color: white;
    margin-bottom: 0;
    width: fit-content;
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    transform: translateX(-50%);
    transition: .2s ease-in;

    &:hover{
        box-shadow: 0px 4px 12px 0px #FFFFFF66 inset;
    }
  }

  .stopBtn {
    background-color: #FF6B6B;
  }

  .playBtn{
    background-color: #20A58A;
  }

.bgContainer{
    width: 100%;
    height: 100%;
    padding: 18px 26px;
    position: relative;
    overflow: hidden;
    background-color: #000;

    .bgImg{
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0.8;
        z-index: 1;
    }
}

.dress{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.dressImgStop, .dressImgStop > *{
    animation: none !important;
}

.dressImg, .dressImg > *{
  width: 94%;
  height: calc(100% - 100px);
  position: absolute;
  z-index: 2;
  bottom: 6%;
  animation-direction: alternate;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

/* Matte Animation (Sharp, Step-by-Step Movement) */
@keyframes matteAnimation {
  0% { transform: translateX(0); }
  25% { transform: translateX(10px); }
  50% { transform: translateX(20px); }
  75% { transform: translateX(30px); }
  100% { transform: translateX(40px); }
}

.movement--matte {
  animation: matteAnimation 2s steps(5, end) infinite;
  animation-fill-mode: forwards;
}

/* Mesh Animation (Short, Quick Steps) */
@keyframes meshAnimation {
  0% { transform: translateX(0); }
  20% { transform: translateX(5px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(15px); }
  80% { transform: translateX(20px); }
  100% { transform: translateX(25px); }
}

.movement--mesh {
  animation: meshAnimation 1.5s ease-in-out infinite alternate;
  animation-fill-mode: forwards;
}

/* Smooth Animation (Gliding Left to Right) */
@keyframes smoothAnimation {
  0% { transform: translateX(0); }
  100% { transform: translateX(50px); }
}

.movement--smooth {
  animation: smoothAnimation 2s ease-in-out infinite alternate;
  animation-fill-mode: forwards;
}

/* Glossy Animation (Circular, Spinning Movements) */
@keyframes glossyAnimation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.movement--glossy {
  animation: glossyAnimation 3s linear infinite;
  animation-fill-mode: forwards;
}